import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "医療 IT の未来に向けて取り組むこと",
  "date": "2018-05-01T03:54:26.000Z",
  "slug": "entry/2018/05/01/01",
  "tags": ["medley"],
  "hero": "./2018_05_01.png",
  "heroAlt": "医療 IT"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、平山です。メドレーのプロダクト開発全般を管掌しています。先日 4/29 (日)に虎ノ門ヒルズフォーラムで開催された`}<a parentName="p" {...{
        "href": "https://clinics-info.medley.life/summit2018"
      }}>{`CLINICS SUMMIT 2018`}</a>{`と合わせて、3 本のニュースリリースをだしました。`}</p>
    <p>{`これらのニュースリリースはひとつのストーリーにもとづいているのですが、それぞれを読んだだけではメッセージが伝わりづらいと思いますので、このブログで補足させて頂きます。`}</p>
    <h1>{`ニュースリリース`}</h1>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="クラウド型電子カルテ「CLINICS カルテ」の提供を開始  〜日医標準レセプトソフト（ORCA）を内包、オンライン診療も実施できる次世代の電子カルテ〜 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frelease%2F20180429-01.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/release/20180429-01.html">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="日医標準レセプトソフト（ORCA）と医療情報システムを容易に連携する API クライアントライブラリ「ORCA API」を公開し、オープンソース化 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frelease%2F20180429-02.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/release/20180429-02.html">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="ブロックチェーンを活用した電子処方せんの管理方式に関する特許を出願 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frelease%2F20180429-03.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/release/20180429-03.html">www.medley.jp</a></cite>
    <cite className="hatena-citation"></cite>
    <h1>{`オンライン診療システムのいま`}</h1>
    <p>{`まず背景として我々が提供しているプロダクト「CLINICS」について振り返るところから話を進めます。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180501/20180501111913.png",
        "alt": "f:id:medley_inc:20180501111913p:plain",
        "title": "f:id:medley_inc:20180501111913p:plain"
      }}></img></p>
    <p>{`オンライン診療システム CLINICS は、2015 年 8 月に厚生労働省から示された遠隔診療に関する通知をうけて、2016 年 2 月にプロダクトをローンチしたところからはじまりました。`}</p>
    <p>{`ローンチ当初はネイティブアプリもなくウェブアプリケーションのみで、オンライン診療の肝となるビデオチャット機能も外部サービスで代替するなど、わかりやすい MVP (Minimum Viable Product) からスタートしました。その後、様々な医療機関で利用頂き、多くの医療機関スタッフの皆様からの叱咤激励をうけながら、プロダクトやオペレーションを磨き進化を続けて参りました。`}</p>
    <p>{`その結果として、現在では契約医療機関は 800 を超え、1 ヶ月に 100 回以上ものオンライン診療を実施するような医療機関もでてきました。また`}<a parentName="p" {...{
        "href": "https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/0000188411.html"
      }}>{`平成 30 年度の診療報酬改定`}</a>{`では`}<strong parentName="p">{`オンライン診療料が新設`}</strong>{`され、オンライン診療というものが正式に医療の現場で認められるようになってきています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20180427/20180427172532.png",
        "alt": "f:id:dev-medley:20180427172532p:plain",
        "title": "f:id:dev-medley:20180427172532p:plain"
      }}></img></p>
    <p>{`しかし、オンライン診療システムの進化の中で常につきまとう課題がありました。それは`}<strong parentName="p">{`電子カルテや医事会計システムなど医療機関の中心で使われるシステムとの連携`}</strong>{`です。`}{` `}</p>
    <h1>{`オンライン診療から電子カルテへ`}</h1>
    <p>{`医療現場の業務において中心で使われるシステムはオンライン診療システムではなく、一般的には電子カルテや医事会計システムとなります。`}</p>
    <p>{`オンライン診療システムは、予約、問診、診察、会計、薬または処方せんの配送、これら一連の業務を対面・オンラインに限らずにワンストップで処理できるという思想で開発を進めてきました。しかし、実際の現場においては、オンライン診療システムでの業務と電子カルテシステムでの業務を連携させるために人手を介在させる必要があり、オンライン診療システムをいれると現場オペレーションが増えてしまうといった課題がありました。`}</p>
    <p>{`このオンライン診療システムにつきまとう課題を解決するため、昨年より電子カルテに関する調査を進めてきました。他社電子カルテシステムとの連携など様々な選択肢があったなか、この調査を経て`}<strong parentName="p">{`現状の電子カルテをとりまく課題と将来の可能性`}</strong>{`を感じ、電子カルテを内製で開発しオンライン診療システムを電子カルテシステムに進化させるという結論に至りました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20180501/20180501101005.png",
        "alt": "f:id:dev-medley:20180501101005p:plain",
        "title": "f:id:dev-medley:20180501101005p:plain"
      }}></img></p>
    <p>{`おりしも 2017 年は 3 省 4 ガイドラインの改定 (医療情報システムの安全管理に関するガイドライン第 5 版) や、日本医師会 ORCA 管理機構による`}<a parentName="p" {...{
        "href": "https://www.orcamo.co.jp/products/orca/cloud.html"
      }}>{`日レセクラウド`}</a>{`や`}<a parentName="p" {...{
        "href": "https://www.orcamo.co.jp/api-council/"
      }}>{`API 拡張 (HAORI)`}</a>{`の提供開始などの動きがあり、医療業界においてもクラウド化の波が確実にきていることを感じました。これが電子カルテ開発の背景です。`}{` `}</p>
    <h1>{`患者とつながる電子カルテ「CLINICS カルテ」`}</h1>
    <p>{`開発した電子カルテシステム「CLINICS カルテ」は、医事会計ソフトである ORCA を会計エンジンとして組み込んだ`}<strong parentName="p">{`ORCA 内包型のクラウド電子カルテ`}</strong>{`となります。`}</p>
    <p>{`医療機関のスタッフは予約から受付、患者管理、診察、オーダリング、会計、請求といった、ひととおりの業務をウェブブラウザだけあれば行うことができます。またオンライン診療の機能が搭載されているため、患者がアプリから予約しチェックインした情報をもとに、医療機関スタッフが患者情報を登録し、そのままオンライン診療を実施し、会計まで進めるということも可能となります(もちろんオンライン診療を実施するための基準を満たしていることが前提です)。`}</p>
    <p>{`まさに`}<strong parentName="p">{`患者とつながる電子カルテ`}</strong>{`ということを実現したプロダクトとなります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20180430/20180430230423.png",
        "alt": "f:id:dev-medley:20180430230423p:plain",
        "title": "f:id:dev-medley:20180430230423p:plain"
      }}></img></p>
    <p>{`もちろんセキュリティ統制の強化についても昨年から本格的に取り組んできておりまして、3 省 4 ガイドラインへの準拠は当然のことながら、第 3 者認証機関による認証取得に向けても動いています (`}<a parentName="p" {...{
        "href": "https://www.medley.jp/notice/20180314.html"
      }}>{`TRUSTe 取得完了`}</a>{`、ISMS 取得作業中)。`}{` `}{` `}</p>
    <h1>{`医療 IT の課題とアプローチ`}</h1>
    <p>{`しかし、CLINICS カルテをはじめとしたクラウド電子カルテが普及するには時間がかかると考えています。`}</p>
    <p>{`クラウドサービスに慣れた若い世代が開業し、システム導入の意思決定をするための世代交代に一定以上の時間が必要であるというのはもちろんですが、それ以上に`}<strong parentName="p">{`医療情報システム関連技術の標準化が遅れている`}</strong>{`ことや、ローカルネットワークを前提とする`}<strong parentName="p">{`院内システムのエコシステムができあがっている`}</strong>{`ためです。これにより、ウェブ系の新興プレイヤーが参入することが難しくなり、結果として医療業界全体がテクノロジーの進化の恩恵をうけづらくなっているように感じます。`}</p>
    <p>{`この現状をふまえ、我々は CLINICS カルテの公開とあわせて、医療 IT の世界をオープンにし、様々な新興プレイヤーが参入しやすい土壌をつくることについてもコミットしていきたいと考えています。その思いが`}<strong parentName="p">{`「ORCA API のオープンソース公開」`}</strong>{`と`}<strong parentName="p">{`「ブロックチェーンを活用した電子処方せん管理方式に関する特許出願」`}</strong>{`という 2 つのニュースリリースにあらわれています。`}{` `}</p>
    <h2>{`ORCA API のオープンソース公開`}</h2>
    <p>{`ORCA API は ORCA((正確には ORCA プロジェクトが提供している日医標準レセプトソフト))が提供する API を Ruby から利用するためのライブラリです。ORCA API をオープンソースとして公開することで、ORCA と接続するウェブアプリケーションの開発が促進されることを期待するものです。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="orca-api/orca-api" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fgithub.com%2Forca-api%2Forca-api" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://github.com/orca-api/orca-api">github.com</a></cite>
    <h2>{`ブロックチェーンを活用した電子処方せん管理方式に関する特許出願`}</h2>
    <p>{`電子処方せんに関してはすでに`}<a parentName="p" {...{
        "href": "https://www.jahis.jp/files/user/04_JAHIS%20standard/17-104_JAHIS%E9%9B%BB%E5%AD%90%E5%87%A6%E6%96%B9%E3%81%9B%E3%82%93%E5%AE%9F%E8%A3%85%E3%82%AC%E3%82%A4%E3%83%89Ver.1.0.r2.pdf"
      }}>{`実装ガイド`}</a>{`が作成され指針が示されていますが、この中で認められているように、実装ガイドに基づいて電子処方せんシステムを実装しても実運用で使うにはいくつかの課題が残ります。また、それらの課題を解決するために`}<a parentName="p" {...{
        "href": "https://www8.cao.go.jp/kisei-kaikaku/suishin/meeting/wg/iryou/20171219/171219iryou05.pdf"
      }}>{`今後の方向性についての議論`}</a>{`も行われているようですが、なかなか前に進む気配が見られません。`}</p>
    <p>{`それに対する我々からのひとつの考えを示してみたのが今回の特許出願となります (我々が独占的に利用するといった意図の特許出願ではありません)。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`名称`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`電子処方せん管理方法、電子処方せん管理システム、及びプログラム`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`内容`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`処方せんの電磁的記録による作成、交付及び保存を実施するための電子処方せん管理方法、電子処方せん管理システム、及びプログラム`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`概要`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ＡＳＰサーバを用いずとも、実運用が可能な電子処方せんを実現するもの`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`この 2 つは現状の医療 IT に存在している課題に対してアプローチしたものになりますが、これ以外にも検査結果データの標準化や HPKI のオープン化と促進 (特定プラットフォーム依存性の排除)、SS-MIX の促進など、標準化という観点で医療 IT の世界には取り組むべき課題が多くあります。`}</p>
    <p>{`標準化においてはトップダウンによるアプローチが理想だと思いますが、トップダウンでの標準化には時間がかかり、`}<strong parentName="p">{`テクノロジーの進化の時間軸との間にギャップ`}</strong>{`が発生しがちです。我々は今回の ORCA API や電子処方せんブロックチェーンでのアプローチのように、トップダウンでの標準化の動きを待つだけでなく、テクノロジーを活用した`}<strong parentName="p">{`ボトムアップの技術提案`}</strong>{`も積極的に行っていきたいと考えています。`}</p>
    <p>{`その結果として、技術の標準化が進み、新興プレイヤーが増え、医療機関間の連携も円滑になり、地域医療構想のような動きも加速され、医療現場の IT 化が進み、医療従事者が診療により専念できる環境がつくられる。`}</p>
    <p>{`そのような世界の実現にむけて我々は取り組んでいくという意思を明確にするために、今回のニュースリリースを公開させて頂きました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20180501/20180501101035.png",
        "alt": "f:id:dev-medley:20180501101035p:plain",
        "title": "f:id:dev-medley:20180501101035p:plain"
      }}></img></p>
    <h1>{`医療 IT の未来に向けて`}</h1>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="医療×インターネットの未来 | メドレー平山の中央突破" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Ftoppa.medley.jp%2F01.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://toppa.medley.jp/01.html">toppa.medley.jp</a></cite>
    <p>{`以前このブログでも書いたとおり、インターネット業界で活躍してきたような、高い能力をもちプロダクトにこだわりをもって開発をしてきたような人が圧倒的に少ないことが、医療 IT における課題であると私は思っています。`}</p>
    <p>{`若く優秀なクリエイターたちが医療 IT の世界に参加し、様々な取り組みをすることで、業界内の循環が進み、結果として業界の進化にもつながるものと考えています。まずは我々が積極的に技術をオープンにしていくことで、その流れの起点をつくっていきたいと思います。`}{` `}</p>
    <p>{`メドレーは「医療ヘルスケア分野の課題を解決する」というミッションのもと、医療 IT の世界における標準化の課題に対しても積極的にアプローチしていきます。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`メドレーではこのような医療業界に存在する課題に取り組んでいきたいメンバーをデザイナー・エンジニアを中心に全職種絶賛募集中です。皆さまからのご応募お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      